import styled from "styled-components"
import ImgSrc from "../../../assets/img/hero_bkg_alt.jpg";
import ImgSrcMobile from "../../../assets/img/hero_bkg_alt_mobile.jpg";

interface IList {
  $openList: boolean
}

interface IFloatingInputSingle {
  $floatText: boolean
  $floatTextError: boolean
}

interface TriggerWrapperInterface {
  $changeColor: boolean
  $floatText: boolean
}

export const CalculationRoot = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background: 
    linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
    ),url(${ImgSrc}) no-repeat;
  background-position: 0 center;
  background-size: 100% auto;
  
  @media (max-width: 1024px) {
    background: linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
      ),
      url(${ImgSrcMobile}) no-repeat;
    background-position-x: 60%;
    background-position-y: 40%;
    background-size: 100%;
  }

  @media (max-width: 767px) {
    background-position-x: 30%;
    background-position-y: 70%;
    background-size: 100%;
  }

  @media (max-width: 567px) {
    background-position-x: 30%;
    background-position-y: 70%;
    background-size: 190%;
  }

  @media (max-width: 384px) {
    background-position-x: 30%;
    background-position-y: 70%;
    background-size: 250%;
  }
`

export const CalculationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 63.25rem;
  padding: 2.5rem;
  width: 100%;

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`

export const MainHeader = styled.h1`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 4rem;
  line-height: 4.25rem;
  margin-bottom: 2rem;
  text-wrap: balance;

  span {
    color: var(--secondary, #fff);
  }

  @media (max-width: 767px) {
    font-size: 3rem;
    line-height: 2.813rem;
  }
`

export const HeaderText = styled.p`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 1.125rem;
  line-height: 1.575rem;
  
  @media (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 25rem;
  margin-right: 3rem;

  @media (max-width: 1023px) {
    padding: 0;
    flex: 1 0 auto;
    width: 100%;
  }
`

export const CalculationCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 0%;
  position: relative;

  h2 {
    font-family: "SourceSans3-Bold";
    font-size: 1.75rem;
    line-height: 1.75rem;
    text-wrap: balance;
  }

  @media (max-width: 1023px) {
    flex: 1 0 auto;
    width: 100%;

    h2 {
      font-size: 1.4rem;
    }
  }
`

export const CalculationForm = styled.form`
  width: 100%;
  max-width: 28.25rem;
  background-color: var(--secondary, #fff);
  border-radius: 1.25rem;
  box-shadow: 1px 4px 16px 0px #B0049C1A;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  gap: 2.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2.5rem;

  label,
  .labelDiv {
    position: relative;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &.flex-1 {
      flex: 1;
    }

    &.w-60 {
      width: 60%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    input {
      width: 100%;
      min-height: 3.25rem;
      position: relative;
      font-family: "SourceSans3-Regular";
      font-size: 0.875rem;
      line-height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--gray3,#ddd);
      background-color: #fff;
      color: var(--gray1, #303130);
      text-overflow: ellipsis;
      box-sizing: border-box;
      padding-top: 0.9375rem;
      padding-right: 0;
      padding-left: 0;

      &::placeholder {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;

    label {
      width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .labelDiv {
      font-size: 1rem;
    }
  }
`

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  input:last-child {
    max-width: 329px;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`

export const SimulateButton = styled.div<{ $formLoading: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  button {
    background-color: var(--primary, #000);
    color: #fff;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 12rem;
    border-radius: 0.625rem;

    &:hover:not(:disabled) {
      background-color: ${(props) =>
        props.$formLoading ? "" : "var(--secondary-salmon, #000)"} !important;
    }
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`

export const TriggerWrapper = styled.div<TriggerWrapperInterface>`
  width: 100%;
  box-sizing: border-box;
  position: relative;

  button {
    width: 100%;
    min-height: 3.25rem;
    position: relative;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray3,#ddd);
    background-color: #fff;
    color: ${(props) => (props.$changeColor ? "var(--black1, #000)" : "var(--gray2, #626362)")};
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin-top: 0.3rem;
    padding-right: 0;
    padding-left: 0;

    .miniText {
      color: var(--gray2, #626362);
      position: absolute;
      font-size: ${(props) => (props.$floatText ? "0.875rem" : "inherit")};
      left: 0;
      cursor: text;
      transition: all 0.2s ease-in-out;
      top: ${(props) => (props.$floatText ? "0px" : "1.5rem")};
      pointer-events: none;
    }

    svg {
      position: absolute;
      right: 0;
      display: flex;
      transition: all 0.3s ease-in-out;
      top: 1.5rem;
    }

    &.openedList {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.w-full {
    width: 100%;
    max-width: 100%;
  }

  &.location-field {
    width: 7rem;
  }

  &.state-field {
    width: 6.5rem;
  }

  &.city-field {
    width: 16.625rem;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    &.state-field {
      width: 100%;
    }
  }
`

export const FloatingInput = styled.span<IFloatingInputSingle>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .miniText {
    color: var(--gray2, #626362);
    position: absolute;
    font-size: ${(props) => (props.$floatText ? "0.875rem" : "1.125rem")};
    cursor: text;
    transition: all 0.2s ease-in-out;
    top: ${(props) => (props.$floatText ? "0px" : "1.5rem")};
    pointer-events: none;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  input {
    width: 100%;
  }

  input:focus ~ .miniText,
  input:active ~ .miniText {
    top: 0px;
    font-size: 0.875rem;
    transition: all 0.2s ease-in-out;
  }
`

export const List = styled.ul<IList>`
  position: absolute;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  top: 3.188rem;
  box-sizing: border-box;
  padding: 0.75rem;
  max-height: 12.5rem;
  overflow-y: auto;
  opacity: ${(props) => (props.$openList ? "1" : "0")};
  visibility: ${(props) => (props.$openList ? "visible" : "hidden")};
  transition: all 0.2s ease-in-out;
  margin: 0;
  z-index: 50;
  scrollbar-width: thin;
  box-shadow: 0px 4px 24px 0px rgba(21, 21, 22, 0.2);
  border-top: 1px solid var(--gray1, #303130);

  &::-webkit-scrollbar {
    width: 8px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: #5d2590;
    border-radius: 12px;
  }

  li {
    color: var(--gray1, #303130);
    font-size: 0.875rem;
    line-height: 1.125rem;
    cursor: pointer;
  }
`

export const SearchCityInput = styled.span`
  position: relative;
  display: flex;

  input {
    min-height: 3.125rem !important;
    padding-top: 0 !important;
    width: 100%;
    padding-left: 2.8125rem !important;
  }

  svg {
    position: absolute;
    left: 0px;
    top: 12px;
    fill: var(--gray2, #626362);
  }
`

export const ApiError = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: red;
    font-size: 0.875rem;
  }
`

export const InputError = styled.p`
  position: absolute;
  bottom: -30px;
  font-size: 0.875rem;
  color: red;
`
