import * as S from "./styles";
import EpocaNegociosSrcSm from "../../../assets/img/epoca_negocios_logo_sm.png";
import ValorSrcSm from "../../../assets/img/valor_logo_sm.png";

const MediaComponent = () => {
  return (
    <S.Media>
      <S.MediaRow>
        <h2>GUD na mídia</h2>
      </S.MediaRow>

      <S.MediaRow>
        <S.MediaImg target="__blank" href="https://epocanegocios.globo.com/empresas/noticia/2024/01/superintendencia-do-cade-aprova-joint-venture-entre-auren-comercializadora-e-telefonica.ghtml">
          <img src={EpocaNegociosSrcSm} />
        </S.MediaImg>
        <S.MediaImg target="__blank" href="https://valor.globo.com/empresas/noticia/2023/12/18/telefnica-brasil-e-auren-energia-formam-joint-venture-focada-em-energia-renovvel.ghtml">
          <img src={ValorSrcSm} style={{ width: "auto" }} />
        </S.MediaImg>
      </S.MediaRow>
      <span className="MediaDesk" />
    </S.Media>
  );
};

export default MediaComponent;
