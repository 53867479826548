import styled from "styled-components";
import ImgSrc from "../../assets/img/hero_bkg_alt.jpg";
import ImgSrcMobile from "../../assets/img/hero_bkg_alt_mobile.jpg";

export const SimulationRoot = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
    ),
    url(${ImgSrc}) no-repeat;
  background-position: 0 center;
  background-size: 100% auto;

  @media (max-width: 1024px) {
    background-size: auto 100%;
    background: linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
    ),
    url(${ImgSrcMobile}) no-repeat;
  }
`;

export const SimulationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 59.25rem;
  padding: 2.5rem;
  width: 100%;

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`;

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 22rem;
  margin-right: 3rem;

  p {
    color: var(--secondary, fff);
  }

  @media (max-width: 1023px) {
    padding: 0;
    flex: 1 0 auto;
    width: 100%;
    margin: 0;
  }
`;

export const DiscountHeader = styled.h1`
  font-family: "SourceSans3-Bold";
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--secondary, fff);
  margin-bottom: 1.5rem;
`;

export const DiscountValue = styled.h2`
  font-family: "SourceSans3-Light";
  color: var(--secondary, #fff);
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
`;

export const DiscountValueIn3Years = styled.h2`
  font-family: "SourceSans3-Light";
  color: var(--secondary, #fff);
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin: 1rem 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

export const DiscountAdvantageTextRow = styled.div`
  display: flex;
  justify-content: flex-start;
  color: var(--secondary, #fff);
  gap: 0.5rem;
  margin: 1rem 0;
  text-wrap: balance;

  svg {
    flex: 0 0 auto;
  }

  p {
    font-family: "SourceSans3-Regular";
    font-size: 1.125rem;
    line-height: 1.2rem;
  }
`;

export const SmallText = styled.p`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
  margin: 2rem 0;
  max-width: 23.75rem;
`;

export const RedoSimulation = styled.div<{ $hideOnMobile: boolean }>`
  display: ${(props) => (props.$hideOnMobile ? "flex" : "none")};

  button {
    all: unset;
    cursor: pointer;
    background-color: transparent;
    text-decoration: underline;
    font-family: "SourceSans3-Regular";
    padding: 0.375rem 0px;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: var(--secondary, fff);
  }

  @media (max-width: 1023px) {
    width: 100%;
    display: ${(props) => (props.$hideOnMobile ? "none" : "flex")};
    justify-content: center;
    margin: 1rem 0 0 0;
  }
`;

export const SimulationCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  position: relative;

  @media (max-width: 1023px) {
    flex: 1 0 auto;
    width: 100%;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
`;

export const SimulationFormWrapper = styled.div`
  position: absolute;
  width: 100%;
  right: 0rem;
  background-color: var(--secondary, #fff);
  border-radius: 1.25rem;
  box-shadow: 1px 4px 16px 0px #B0049C1A;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  gap: 0;

  @media (max-width: 1023px) {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    height: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const FormTitle = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 0;

  p {
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.225rem;
  }

  strong {
    font-family: "SourceSans3-Bold";
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const SimulationResultForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  input[type="text"],
  input[type="email"] {
    border-bottom: 1px solid var(--gray3, #ddd);
    background: #fff;
    height: 3.75rem;
    box-sizing: border-box;
    flex: 1;
    padding-top: 1.25rem;
    min-height: 3.25rem;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: var(--gray1, #303130);
  }

  @media (max-width: 768px) {
    input[type="text"],
    input[type="email"] {
      font-size: 0.875rem;
    }
  }
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  font-family: "SourceSans3-Regular";

  label {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    input:focus ~ span,
    input:active ~ span {
      top: 15px;
      font-size: 0.75rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    label {
      span {
        font-size: 0.75rem;
      }
    }
  }
`;

export const FloatingText = styled.span<{ $willFloat: boolean }>`
  position: absolute;
  color: var(--gray2, #626362);
  font-size: ${(props) => (props.$willFloat ? "0.75rem" : "1rem")};
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  top: ${(props) => (props.$willFloat ? "15px" : "50%")};
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s ease-in-out;
`;

export const CheckboxGroupWrapper = styled.div`
  display: flex;
  font-family: "SourceSans3-Regular";
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CheckboxGroupTitle = styled.span`
  color: #282828;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.225rem;

  &.errorMsg {
    color: #cc1f59;
  }
`;

export const CheckboxArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-left: auto;

  label {
    color: #000;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.125rem;
  }

  .control {
    display: block;
    position: relative;
    padding-left: 1.4375rem;
    cursor: pointer;
  }

  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control_indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #ffffff;
    border: 1px solid var(--gray5, #EEF1F4);
    border-radius: 4px;
  }

  .control input:checked ~ .control_indicator {
    border: 1px solid var(--gray5, #EEF1F4);
    background: var(--gray5, #EEF1F4);
  }

  .control_indicator:after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
  }

  .control input:checked ~ .control_indicator:after {
    display: block;
  }

  .control_checkbox .control_indicator:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.86983 3.01909C10.0434 3.19266 10.0434 3.47406 9.86983 3.64763L4.98094 8.53652C4.80737 8.71009 4.52596 8.71009 4.3524 8.53652L2.13017 6.3143C1.95661 6.14073 1.95661 5.85932 2.13017 5.68576C2.30374 5.51219 2.58515 5.51219 2.75871 5.68576L4.66667 7.59371L9.24129 3.01909C9.41485 2.84552 9.69626 2.84552 9.86983 3.01909Z' fill='%23b0049c'/%3E%3C/svg%3E%0A");
    top: 0;
    left: 2px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const AgilizeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & div:first-child {
    margin-bottom: 1.5rem;
  }
`;

export const AgilizeTitle = styled.div`
  p {
    color: var(--gray1, #303030);
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.25rem;

    &.highlighted {
      font-family: "SourceSans3-Bold";
      color: var(--gray1, #303030);
    }
  }

  @media (max-width: 768px) {
    margin-top: 0.625rem;

    p {
      font-size: 0.875rem;
    }

    p:last-child {
      max-width: 16.875rem;
    }
  }
`;

export const SendInvoice = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "SourceSans3-Regular";
  color: var(--primary, #000);
`;

export const InvoiceFileInputWrapper = styled.span`
  display: flex;
  color: #303130;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  align-items: flex-end;
  gap: 0.3125rem;

  span {
    display: flex;
    flex-direction: column;
  }

  button {
    color: var(--primary, #000);
  }

  label {
    color: var(--primary, #000);
    text-decoration: underline;
    cursor: pointer;
    max-width: 8.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    input {
      display: none;
    }
  }
`;

export const SendInvoiceButton = styled.span<{ $formLoading: boolean }>`
  margin-top: 1.5rem;

  button {
    background-color: var(--primary, #000);
    color: #fff;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 12rem;
    border-radius: 0.625rem;

    &:hover:not(:disabled) {
      background-color: ${(props) =>
        props.$formLoading ? "" : "var(--secondary-salmon, #000)"} !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const InvoiceFormFooter = styled.div`
  margin-top: 1rem;

  p {
    color: #1d1d1d;
    font-family: "SourceSans3-Regular";
    font-size: 0.75rem;
    line-height: 1.125rem;
    max-width: 27.1875rem;
  }

  a {
    color: var(--gray1, #303030);
  }
`;

export const FileError = styled.p`
  color: #cc1f59;
  font-size: 0.875rem;
`;
export const FieldErrorMsg = styled.p`
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: #cc1f59;
  min-height: 1.0625rem;
`;

export const CheckboxSection = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
