import styled from "styled-components";

export const Media = styled.section`
  position: relative;
  background: var(--secondary, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 2.5rem 3rem 2.5rem;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    color: var(--black2, #1d1d1d);
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
`;

export const MediaImg = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 70%;
  }
`;

export const MediaRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 59.25rem;
  gap: 2rem;
  z-index: 1;

  .Media {
    height: 0;
    width: 0;
  }
`;
