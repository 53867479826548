import styled from "styled-components";
import ImgSrc from "../../assets/img/hero_bkg_alt.jpg";
import ImgSrcMobile from "../../assets/img/hero_bkg_alt_mobile.jpg";

export const IneligibleRoot = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
    ),
    url(${ImgSrc}) no-repeat;
  background-position: 0 center;
  background-size: 100% auto;

  @media (max-width: 1024px) {
    background-size: auto 100%;
    background: linear-gradient(
      90deg,
      rgba(65, 6, 59, 0.6) 0%,
      rgba(174, 14, 145, 0.24) 62%,
      rgba(48, 176, 246, 0.24) 97.5%
    ),
    url(${ImgSrcMobile}) no-repeat;
  }
`;

export const IneligibleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 59.25rem;
  padding: 2.5rem;
  width: 100%;

  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`;

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 25rem;
  margin-right: 3rem;

  @media (max-width: 1023px) {
    padding: 0;
    flex: 1 0 auto;
    width: 100%;
  }
`;

export const MainHeader = styled.h1`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 4rem;
  line-height: 4.25rem;
  margin-bottom: 2rem;
  text-wrap: balance;

  span {
    color: var(--secondary, #fff);
  }

  @media (max-width: 767px) {
    font-size: 3rem;
    line-height: 2.813rem;
  }
`;

export const HeaderText = styled.p`
  font-family: "SourceSans3-Regular";
  color: var(--secondary, #fff);
  font-size: 1.125rem;
  line-height: 1.575rem;

  @media (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`;

export const RedoSimulation = styled.span<{ $hideOnMobile: boolean }>`
  display: ${(props) => (props.$hideOnMobile ? "flex" : "none")};

  button {
    all: unset;
    cursor: pointer;
    background-color: transparent;
    text-decoration: underline;
    font-family: "SourceSans3-Regular";
    padding: 0.375rem 0px;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: var(--secondary, fff);
    margin: 1rem 0 0 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    display: ${(props) => (props.$hideOnMobile ? "none" : "flex")};
    justify-content: center;
  }
`;

export const IneligibleFormCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    flex: 1 0 auto;
    width: 100%;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  p {
    font-family: "SourceSans3-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const IneligibleFormWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: -1rem;
  background-color: var(--secondary, #fff);
  border-radius: 1.25rem;
  box-shadow: 1px 4px 16px 0px #b0049c1a;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  gap: 1rem;

  @media (max-width: 1023px) {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    left: 0;
    height: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const FormTitle = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;

  p {
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.225rem;
  }

  strong {
    font-family: "SourceSans3-Bold";
    font-size: 0.875rem;
    line-height: 1.225rem;
  }
`;

export const IneligibleResultForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  input[type="text"],
  input[type="email"] {
    border-bottom: 1px solid var(--gray3, #ddd);
    background: #fff;
    height: 3.75rem;
    box-sizing: border-box;
    flex: 1;
    padding-top: 1.25rem;
    min-height: 3.25rem;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: var(--gray1, #303130);
  }

  @media (max-width: 768px) {
    input[type="text"],
    input[type="email"] {
      font-size: 0.875rem;
    }
  }
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  font-family: "SourceSans3-Regular";

  label {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    input:focus ~ span,
    input:active ~ span {
      top: 15px;
      font-size: 0.75rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    label {
      span {
        font-size: 0.75rem;
      }
    }
  }
`;

export const FloatingText = styled.span<{ $willFloat: boolean }>`
  position: absolute;
  color: var(--gray2, #626362);
  font-size: ${(props) => (props.$willFloat ? "0.75rem" : "1rem")};
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  top: ${(props) => (props.$willFloat ? "15px" : "50%")};
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s ease-in-out;
`;

export const SendButton = styled.span<{ $formLoading: boolean }>`
  margin-top: 0.5rem;

  button {
    background-color: var(--primary, #000);
    color: #fff;
    font-family: "SourceSans3-Regular";
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 12rem;
    border-radius: 0.625rem;

    &:hover:not(:disabled) {
      background-color: ${(props) =>
        props.$formLoading ? "" : "var(--secondary-salmon, #000)"} !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const FormFooter = styled.div`
  margin-top: 1rem;

  p {
    color: #1d1d1d;
    font-family: "SourceSans3-Regular";
    font-size: 0.75rem;
    line-height: 1.125rem;
    max-width: 27.1875rem;
  }

  a {
    color: var(--gray1, #303030);
  }
`;

export const FieldErrorMsg = styled.p`
  font-size: 0.875rem !important;
  color: #cc1f59;
  min-height: 1.0625rem;
`;
