import styled from "styled-components";
import BigNumberLeftImgSrc from "../../../assets/img/bignumber_bkg_left.png";
import BigNumberRightImgSrc from "../../../assets/img/bignumber_bkg_right.png";

export const Advantage = styled.section`
  position: relative;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 2.5rem;
  gap: 2rem;
  min-height: 23.5rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    color: var(--black2, #1d1d1d);
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
`;

export const AdvantageImg = styled.img`
  width: 100%;
`;

export const AdvantageRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 59.25rem;
  gap: 2rem;
  z-index: 1;

  .Advantage {
    height: 0;
    width: 0;
  }
`;

export const SideImages = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -4.875rem;
  width: 100%;
  max-width: 100%;
  overflow-x: clip;
  z-index: -1;
  justify-content: space-between;
`;

export const SideImageLeft = styled.img`
  position: relative;
  top: 2rem;
  height: 272px;

  content: url(${BigNumberLeftImgSrc});
  object-fit: contain;

  @media (max-width: 1023px) {
    top: 8rem;
  }

  @media (max-width: 768px) {
    top: -2rem;
  }
`;

export const SideImageRight = styled.img`
  position: relative;
  top: -8rem;
  height: 272px;

  content: url(${BigNumberRightImgSrc});
  object-fit: contain;

  @media (max-width: 1023px) {
    top: 9rem;
  }

  @media (max-width: 768px) {
    top: 6rem;
  }
`;

export const MarketFloatRow = styled.div`
  position: absolute;
  top: -4.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 63.438rem;
  gap: 0;
  background: var(--gray5, #000);
  border-radius: 1.25rem;

  @media (max-width: 1023px) {
    position: relative;
    top: 0;
  }

  @media (max-width: 767px) {
    flex-wrap: nowrap;
    gap: 16px;
    background: none;
    overflow-x: scroll;
    border-radius: 0;
    width: 110%;
  }
`;

export const MarketCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
  width: calc(33.333% - 4rem);
  text-align: center;

  @media (max-width: 767px) {
    min-width: 200px;
    border-radius: 1.25rem;
    background: var(--gray5, #000);
  }

  @media (min-width: 768px) {
    &::after {
      opacity: 0.5;
      border-right: 1px solid var(--primary, #fff);
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 20%;
      width: 100%;
      height: 60%;
    }
  }

  &:last-child::after {
    border: 0;
  }
`;

export const MarketFeatureHead = styled.h2`
  color: var(--primary, #fff) !important;
  font-family: "SourceSans3-Regular";
  font-size: 3.125rem;
  margin: 0 !important;
`;

export const MarketFeatureText = styled.p`
  color: var(--primary, #fff);
  font-family: "SourceSans3-Regular";
  font-size: 1rem;
  max-width: 13.5rem;
`;

export const AdvantageText = styled.div`
  font-family: "SourceSans3-Regular";
  max-width: 42rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-wrap: balance;

  strong {
    font-family: "SourceSans3-Bold";
    color: var(--primary, #000);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

export const FeatureFloatRow = styled.div`
  position: absolute;
  bottom: -3rem;
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  align-items: center;
  width: 100%;
  max-width: 63.438rem;
  gap: 1rem;

  overflow-x: scroll;

  @media (max-width: 1023px) {
    position: relative;
    bottom: 0;
    width: 110%;
  }
`;

export const FeatureCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 2.125rem;
  width: calc(25% - 5rem);
  min-width: 144px;
  height: 15.75rem;
  text-align: center;
  background-color: var(--primary, #e5e5e5);
  border-radius: 1.25rem;
  z-index: 1;
  color: var(--secondary, #000) !important;
  stroke-width: 0rem !important;

  &::after {
    opacity: 0.5;
    border-right: 1px solid var(--secondary, #fff);
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    height: 60%;
  }

  svg {
    height: 114px;
  }

  &:last-child::after {
    border: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const CardFeatureHead = styled.h2`
  color: var(--secondary, #000) !important;
  font-family: "SourceSans3-Regular";
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  margin: 0 !important;
  text-wrap: balance;
`;

export const CardFeatureText = styled.p`
  color: var(--secondary, #000);
  font-family: "SourceSans3-Regular";
  font-size: 0.875rem !important;
  line-height: 1rem !important;
`;
