import styled from "styled-components";
import ImgSrc from "../../assets/img/hero_bkg_alt.jpg";
import ImgSrcMobile from "../../assets/img/hero_bkg_alt_mobile.jpg";

export const OutcomeRoot = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(
        90deg,
        rgba(65, 6, 59, 0.6) 0%,
        rgba(174, 14, 145, 0.24) 62%,
        rgba(48, 176, 246, 0.24) 97.5%
    ),
    url(${ImgSrc}) no-repeat;
    background-position: 0 center;
    background-size: 100% auto;

    @media (max-width: 1024px) {
        background-size: auto 100%;
        background: linear-gradient(
            90deg,
            rgba(65, 6, 59, 0.6) 0%,
            rgba(174, 14, 145, 0.24) 62%,
            rgba(48, 176, 246, 0.24) 97.5%
        ),
        url(${ImgSrcMobile}) no-repeat;
    }
`;

export const OutcomeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 59.25rem;
    padding: 2.5rem;
    width: 100%;

    @media (max-width: 767px) {
        padding: 1.5rem;
    }
`;

export const InfoCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 22rem;
    margin-right: 3rem;

    p {
        color: var(--secondary, fff);
    }

    @media (max-width: 1023px) {
        padding: 0;
        flex: 1 0 auto;
        width: 100%;
        margin: 0;
    }
`;

export const RedoSimulation = styled.span<{ $hideOnMobile: boolean }>`
    display: ${(props) => (props.$hideOnMobile ? "flex" : "none")};

    button {
        all: unset;
        cursor: pointer;
        background-color: transparent;
        text-decoration: underline;
        font-family: "SourceSans3-Regular";
        padding: 0.375rem 0px;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: var(--secondary, fff);
    }

    @media (max-width: 1023px) {
        display: ${(props) => (props.$hideOnMobile ? "none" : "flex")};
        text-align: center;
        justify-content: center;
        margin: 1rem 0 0 0;
    }
`;

export const RedoSimulationAlt = styled.span`
    align-self: center;

    button {
        all: unset;
        cursor: pointer;
        color: var(--gray3, #ddd);
        background-color: transparent;
        text-decoration: underline;
        font-family: "SourceSans3-Regular";
        padding: 0.375rem 0px;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
    }

    @media (max-width: 768px) {
        color: #fff;
        text-align: center;
        margin: 0;
    }
`;

export const DiscountHeader = styled.h1`
    font-family: "SourceSans3-Bold";
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--secondary, fff);
    margin-bottom: 1.5rem;
`;

export const DiscountValue = styled.h2`
    font-family: "SourceSans3-Light";
    color: var(--secondary, #fff);
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 768px) {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }
`;

export const DiscountValueIn3Years = styled.h2`
    font-family: "SourceSans3-Light";
    color: var(--secondary, #fff);
    font-size: 2.5rem;
    line-height: 2.75rem;
    margin: 1rem 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }
`;

export const DiscountAdvantageTextRow = styled.div`
    display: flex;
    justify-content: flex-start;
    color: var(--secondary, #fff);
    gap: 0.5rem;
    margin: 1rem 0;
    text-wrap: balance;

    svg {
        flex: 0 0 auto;
    }

    p {
        font-family: "SourceSans3-Regular";
        font-size: 1.125rem;
        line-height: 1.2rem;
    }
`;

export const SmallText = styled.p`
    font-family: "SourceSans3-Regular";
    color: var(--secondary, #fff);
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;
    margin: 2rem 0;
    max-width: 23.75rem;
`;

export const OutcomeFormCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    position: relative;
    text-align: center;

    @media (max-width: 1023px) {
        flex: 1 0 auto;
        width: 100%;
    }

    h2 {
        margin-top: 2rem;
        font-family: "SourceSans3-Bold";
        font-size: 2.0rem;
        line-height: 2.5rem;
    }

    p {
        font-family: "SourceSans3-Regular";
        font-size: 1rem;
        line-height: 1.5rem;
    }

    span {
        font-family: "SourceSans3-Regular";
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--primary, #000);
    }

    svg {
        color: var(--primary, #000);
    }
`;

export const OutcomeFormWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: -1rem;
    background-color: var(--secondary, #fff);
    border-radius: 1.25rem;
    box-shadow: 1px 4px 16px 0px #b0049c1a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    padding-right: 4rem;
    padding-left: 4rem;
    gap: 2rem;

    @media (max-width: 1023px) {
        margin-top: 2rem;
        position: relative;
        width: 100%;
        left: 0;
        height: auto;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }
`;

export const QuestionsButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 2rem;

    button {
        background-color: var(--primary, #000);
        color: #fff;
        font-family: "SourceSans3-Regular";
        font-size: 0.875rem;
        line-height: 1.125rem;
        width: 15rem;
        border-radius: 0.625rem;
        padding: 1rem;

        &:hover{
        background-color: var(--secondary-salmon, #000) !important;
        }
    }

    @media (max-width: 768px) {
        button {
        width: 100%;
        }
    }
`

export const SocialWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.875rem;
    margin-left: 1rem;
`

export const SocialLink = styled.a`
    text-decoration: none;
    color: var(--primary,#fff);
    display: flex; 
    align-items: center; 
    gap: 0.5rem;
`
